import React from "react";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { Box, Typography, Button } from "@mui/material";
import BgDefaultPost from "../../assets/images/BgDefaultPost.png";
import LogoFull from "../../assets/images/LogoFull.png";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Paths as PathsConstant } from "../../constants/Route";
import { useNavigate } from "react-router";

type Props = {};

const InviteUserPost = observer((props: Props) => {
  const appName = process.env.REACT_APP_Name;
  const { t } = useTranslation();
  const { UserStore } = useStores();
  const navigate = useNavigate();

  const handleNavigateToCreatePost = () => {
    UserStore.isLoggedIn
      ? navigate(PathsConstant.marketPlace.post)
      : UserStore.onOpenModalLogin();
  };

  return (
    <Box
      border={`2px solid ${StyleVariables["color-primary"]}`}
      paddingX={2}
      paddingY={4}
      color={StyleVariables["color-black"]}
      bgcolor={StyleVariables["color-white"]}
      textAlign={"center"}
      maxHeight={{ md: 145 }}
      height={{ md: "100%" }}
      sx={{
        backgroundImage: `url(${BgDefaultPost})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <img
        src={LogoFull}
        alt={appName}
        height={40}
        style={{ objectFit: "contain" }}
      />
      <Typography variant="body1" color={StyleVariables["color-black"]}>
        {t("marketplace.inviteUser")}
      </Typography>
      <Box marginX={"auto"} marginTop={2}>
        <Button variant="contained" onClick={handleNavigateToCreatePost}>
          {t("marketplace.post")}
        </Button>
      </Box>
    </Box>
  );
});

export default InviteUserPost;

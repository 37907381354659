import React from "react";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import GeneralLayout from "../layouts/General";
import ContactLocationImage from "../assets/images/ContactLocation.jpg";
import StyleVariables from "../assets/styles/Variable.module.scss";
import ImageIcon from "../components/ImageIcon";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";

type Props = {};

const ContactUs = (props: Props) => {
  const contactFacebook = process.env.REACT_APP_CONTACT_FACEBOOK;
  const contactMail = process.env.REACT_APP_CONTACT_MAIL;
  const { t } = useTranslation();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  return (
    <GeneralLayout>
      <Box className="container-content contact-container">
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box paddingTop={3}>
                <Typography
                  variant="h4"
                  color={StyleVariables["color-black"]}
                  marginBottom={4}
                >
                  {t("contactUs.title")}
                </Typography>
                <Box color={StyleVariables["color-neutral"]} marginBottom={3}>
                  <Link
                    href={contactFacebook}
                    variant="body1"
                    underline="none"
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={3}
                    rel="noopener"
                    target="_blank"
                  >
                    <ImageIcon iconClassName="image-icon-green facebook" />
                    &nbsp;&nbsp;
                    {contactFacebook}
                  </Link>
                  <Link
                    href={`mailto:${contactMail}`}
                    variant="body1"
                    underline="none"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <ImageIcon iconClassName="image-icon-green contact" />
                    &nbsp;&nbsp;
                    {contactMail}
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box paddingTop={3}>
                <Typography
                  variant="h4"
                  color={StyleVariables["color-black"]}
                  marginBottom={4}
                >
                  {t("contactUs.office")}
                </Typography>
                <Box color={StyleVariables["color-neutral"]} marginBottom={3}>
                  <Typography variant="body1">
                    {t("contactUs.officeName")}
                  </Typography>
                  <Typography variant="body1">
                    {t("contactUs.location")}
                  </Typography>
                </Box>
                <img
                  src={ContactLocationImage}
                  alt="contact-location"
                  width={"100%"}
                  height={"100%"}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </GeneralLayout>
  );
};

export default ContactUs;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { ColorPatterns as ColorPatternsConstant } from "../../constants/Product";

type Props = {
  labels: string[];
  datasets: any[];
  options?: {};
};

const LineGraph = (props: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  defaults.font.family = StyleVariables["font-family-primary"];

  const options = {
    responsive: true,
    spanGaps: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          font: {
            size: 12,
            family: "Kanit",
          },
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, i: number) => {
              const hidden = !chart.isDatasetVisible(i);
              return {
                ...dataset,
                text: dataset.label,
                datasetIndex: i,
                fillStyle: !hidden
                  ? dataset.backgroundColor
                  : `${dataset.backgroundColor}33`,
                lineWidth: 0,
                fontColor: !hidden
                  ? StyleVariables["color-black"]
                  : `${StyleVariables["color-black"]}33`,
              };
            });
          },
        },
        onHover: function (event: any, legendItem: any, legend: any) {
          const ci: any = legend.chart;
          const hoveredDatasetIndex = legendItem.datasetIndex;

          ci.data.datasets.forEach((dataset: any, index: number) => {
            if (index !== hoveredDatasetIndex) {
              dataset.backgroundColor = `${ColorPatternsConstant[index]}33`;
              dataset.borderColor = `${ColorPatternsConstant[index]}33`;
              dataset.borderWidth = 1;
            } else {
              dataset.backgroundColor = ColorPatternsConstant[index];
              dataset.borderColor = ColorPatternsConstant[index];
              dataset.borderWidth = 2;
            }
          });
          event.native.target.style.cursor = "pointer";
          ci.update();
        },
        onLeave: function (event: any, legendItem: any, legend: any) {
          const ci: any = legend.chart;
          ci.data.datasets.forEach((dataset: any, index: number) => {
            dataset.backgroundColor = ColorPatternsConstant[index];
            dataset.borderColor = ColorPatternsConstant[index];
            dataset.borderWidth = 1;
          });
          ci.update();
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: StyleVariables["color-white"],
        titleColor: StyleVariables["color-black"],
        titleSpacing: Number(StyleVariables.spacing) * 3,
        titleMarginBottom: Number(StyleVariables.spacing),
        bodyColor: StyleVariables["color-neutral"],
        bodySpacing: Number(StyleVariables.spacing) * 2,
        padding: Number(StyleVariables.spacing),
        cornerRadius: 4,
        boxPadding: 3,
        borderColor: StyleVariables["color-neutralDark"],
        borderWidth: 1,
        usePointStyle: true,
      },
    },
  };

  return (
    <Line
      options={{
        ...options,
        ...props.options,
      }}
      data={{ labels: props.labels, datasets: props.datasets }}
    />
  );
};

export default LineGraph;

import React from "react";
import { Box, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";

type Props = {
  numberOfNotify: number;
};

const NotifyDot = (props: Props) => {
  return (
    <Box
      bgcolor={StyleVariables["color-warning"]}
      width={20}
      height={20}
      borderRadius={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="caption" color={StyleVariables["color-white"]}>
        {props.numberOfNotify <= 99 ? props.numberOfNotify : "99+"}
      </Typography>
    </Box>
  );
};

export default NotifyDot;

import React from "react";
import { Box, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import LogoGray from "../../assets/images/LogoGray.png";
import ImageIcon from "../ImageIcon";
import { Dayjs } from "dayjs";
import { DateBuddhistEra as DateBuddhistEraUtil } from "../../utils/DateFormat";
import DefaultThumbnailPost from "../../assets/images/DefaultPostThumbnail.png";

type Props = {
  id: string | number;
  imageUrl: string;
  type: string;
  title: string;
  volume: string;
  unit: string;
  province: string;
  description?: string;
  announceImageUrl: string;
  announceName: string;
  announceDate: string | Dayjs;
  isExample?: boolean;
  onClick?: (id: string | number) => void;
};

const MarketplacePost = (props: Props) => {
  return (
    <Box
      border={`2px solid ${StyleVariables["color-primary"]}`}
      padding={{ xs: 1, md: 2 }}
      color={StyleVariables["color-black"]}
      bgcolor={StyleVariables["color-white"]}
      className={`${props.onClick && "cursor-pointer"}`}
      onClick={() => props.onClick && props.onClick(props.id)}
    >
      <Box display={"grid"} gridTemplateColumns={"110px auto"} gap={2}>
        <Box width={"100%"} height={80}>
          <img
            src={props.imageUrl || DefaultThumbnailPost || LogoGray}
            alt="product"
            className="img-object-cover"
            loading="lazy"
          />
        </Box>
        <Box overflow={"hidden"}>
          <Box display={"flex"} gap={2} overflow={"hidden"} marginBottom={1}>
            <Typography
              variant="body1"
              color={StyleVariables["color-warning"]}
              minWidth={38}
              className="text-underline"
            >
              {props.type}
            </Typography>
            <Typography
              variant="body1"
              color={StyleVariables["color-primary"]}
              className="text-underline"
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
            >
              {props.title}
            </Typography>
          </Box>
          <Typography variant="body1" color={StyleVariables["color-primary"]}>
            {props.volume}{" "}
            <span style={{ color: StyleVariables["color-neutral"] }}>
              {props.unit}
            </span>
          </Typography>
          <Typography variant="body1">{props.province}</Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        marginTop={2}
        width={"100%"}
        overflow={"hidden"}
        whiteSpace={"nowrap"}
        textOverflow={"ellipsis"}
      >
        {props.description || "-"}
      </Typography>
      <Box
        borderTop={`1px solid ${StyleVariables["color-black"]}`}
        marginTop={1}
      >
        <Box
          display={"grid"}
          alignItems={"center"}
          gridTemplateColumns={{ xs: "30px auto 80px", md: "40px auto 80px" }}
          gap={2}
          marginTop={1}
        >
          {props.announceImageUrl ? (
            <Box
              overflow={"hidden"}
              borderRadius={"100%"}
              width={{ xs: 30, md: 40 }}
              height={{ xs: 30, md: 40 }}
            >
              <img
                src={props.announceImageUrl}
                alt={`profile-${props.announceName}`}
                className="img-object-cover"
                loading="lazy"
              />
            </Box>
          ) : (
            <Box className="img-avartar-default">
              <ImageIcon iconClassName="image-icon-gray avatar" />
            </Box>
          )}
          <Typography
            variant="body2"
            color={StyleVariables["color-primary"]}
            width={"100%"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {props.announceName}
          </Typography>
          <Typography
            variant="body2"
            color={StyleVariables["color-neutral"]}
            textAlign={"right"}
            width={"100%"}
          >
            {DateBuddhistEraUtil(props.announceDate)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketplacePost;

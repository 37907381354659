import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import useSSR from "use-ssr";
import GeneralLayout from "../layouts/General";
import { Box, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import SearchProductInput from "../components/inputs/SearchProduct";
import { Link } from "react-router-dom";
import { Product as ProductModel } from "../models/Product";
import { GetProductGallery as GetProductGalleryService } from "../services/Product";
import { Paths as PathsConstant } from "../constants/Route";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";
import LogoGray from "../assets/images/LogoGray.png";

type Props = {};

const Home = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  var { isBrowser } = useSSR();
  const [productGallery, setProductGallery] = useState<ProductModel[]>([]);

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    onFetchProductGallery();
  }, []);

  const onFetchProductGallery = async () => {
    const response = await GetProductGalleryService();
    response.success && setProductGallery([...response.data]);
  };

  return (
    <GeneralLayout>
      <>
        <Box className="container-content bg-white" display={"flex"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            minWidth={{ md: 500 }}
            marginX={"auto"}
          >
            <Box marginBottom={6}>
              <Typography
                variant="h1"
                textAlign={"center"}
                color={StyleVariables["color-black"]}
              >
                {t("home.heading.compare")}
                <span style={{ color: StyleVariables["color-primary"] }}>
                  {t("home.heading.price")}
                </span>
                <br />
                {t("home.heading.product")}
              </Typography>
              <Typography
                variant="subtitle1"
                marginTop={4}
                textAlign={"center"}
                color={StyleVariables["color-neutral"]}
              >
                {t("home.subtitle")}
              </Typography>
            </Box>
            <SearchProductInput />
          </Box>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={`repeat(${productGallery.length}, 180px)`}
          gap={2}
          padding={2}
          bgcolor={StyleVariables["color-secondary"]}
          sx={{ overflowX: "scroll" }}
          justifyContent={{
            xs:
              isBrowser && 196 * productGallery.length > window.innerWidth
                ? "flex-start"
                : "center",
          }}
          minHeight={180}
        >
          {productGallery.map((product, index) => {
            return (
              <Link
                to={PathsConstant.product.price.today(`${product.id}`)}
                key={index}
                style={{ width: 180, height: 180 }}
              >
                <img
                  src={
                    product.storage
                      ? `${apiUrl}/storages/${product.storage?.id}`
                      : LogoGray
                  }
                  alt={`${product.nameTh}`}
                  width={"100%"}
                  height={"100%"}
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
              </Link>
            );
          })}
        </Box>
      </>
    </GeneralLayout>
  );
};

export default Home;

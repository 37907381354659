import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Link as LinkMaterial,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useMetaTags } from "react-metatags-hook";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import GeneralLayout from "../../layouts/General";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import LogoGray from "../../assets/images/LogoGray.png";
import { GetProductById as GetProductByIdService } from "../../services/Product";
import { GetPostByProductId as GetPostsByProductIdService } from "../../services/Marketplace";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Product as ProductModel } from "../../models/Product";
import { SubProduct as SubProductModel } from "../../models/SubProduct";
import {
  GetPostsQuery as GetPostsQueryModel,
  Post as PostModel,
} from "../../models/Marketplace";
import { FullDateBuddhistEra as FullDateBuddhistEraUtil } from "../../utils/DateFormat";
import {
  ColorPatterns as ColorPatternsConstant,
  TypesProduct as TypesProductConstant,
} from "../../constants/Product";
import { Paths as PathsConstant } from "../../constants/Route";
import SubProductPriceList from "../../components/lists/SubProductPrice";
import LineGraphChart from "../../components/charts/LineGraph";
import SocialShare from "../../components/SocialShare";
import MarketplacePostCard from "../../components/cards/MarketplacePost";
import GoogleAdsense from "../../components/GoogleAdsense";
import ReactGA from "react-ga4";
import InviteUserPost from "../../components/cards/InviteUserPost";

type Props = {};

const PriceToday = observer((props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const frontUrl = process.env.REACT_APP_FRONT_URL;
  const slotGoogleAdsenseDesktop =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_PRODUCT_PRICE_TODAY_DESKTOP;
  const slotGoogleAdsenseMobile =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_PRODUCT_PRICE_PAST_MOBILE;
  const { SnackbarStore, UserStore } = useStores();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { productId } = useParams();
  const { t, i18n } = useTranslation();
  const postFilter: GetPostsQueryModel = {
    sortBy: "createdAt",
    sortOrder: "DESC",
    type: "",
    productId: Number(productId),
    limit: 3,
    skip: 0,
    keyword: "",
  };
  const [product, setProduct] = useState<ProductModel>({
    nameEn: "",
    nameTh: "",
    description: "",
  });
  const [priceTodaySubProducts, setPriceTodaySubProducts] = useState<any>([]);
  const [subProducts, setSubProducts] = useState<SubProductModel[]>([]);
  const [priceList, setPriceList] = useState<any[]>([]);
  const [chartLabels, setChartLabels] = useState<any>([]);
  const [chartDatasets, setChartDatasets] = useState<any>([]);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [metaDescription, setMetaDescription] = useState<string>("");

  useEffect(() => {
    fetchProductById();
    fetchPosts();
  }, [productId]);

  useMetaTags(
    {
      title: `ราคา${product.nameTh}วันนี้`,
      description: metaDescription,
      metas: [
        { property: "og:title", content: `ราคา${product.nameTh}วันนี้` },
        { property: "og:description", content: metaDescription },
        {
          property: "og:image",
          content:
            (product.storage && `${apiUrl}/storages/${product.storage?.id}`) ||
            "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: `ราคา${product.nameTh}วันนี้`,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: `ราคา${product.nameTh}วันนี้` },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ],
    },
    [product, metaDescription]
  );

  useEffect(() => {
    mappingChartData(priceList, subProducts);
  }, [i18n.language]);

  const fetchProductById = async () => {
    SnackbarStore.onOpenSnackbarProcessing("");
    const startDate = dayjs().add(-15, "days").format("YYYY-MM-DD");
    const endDate = dayjs().format("YYYY-MM-DD");
    const response = await GetProductByIdService(productId || "", {
      startDate,
      endDate,
    });
    mappingTodayPrices(response.data?.priceList, response.data?.subProducts);
    mappingChartData(response.data?.priceList, response.data?.subProducts);
    setProduct(response.data);
    setSubProducts(response.data?.subProducts);
    setPriceList(response.data?.priceList);
    setMetaDescription(
      response.data?.subProducts
        .map((subProduct: SubProductModel) => `ราคา${subProduct.nameTh}`)
        .join(" ")
    );
    SnackbarStore.onCloseSnackbarProcessing();
    ReactGA.event("select_content", {
      content_type: "product",
      content_id: response.data?.id,
    });
  };

  const fetchPosts = async () => {
    const { productId } = postFilter;
    const response = await GetPostsByProductIdService(productId || 0);
    response.success && setPosts([...response.data]);
  };

  const showMore = () => {
    if (!posts.length) return null;
    return (
    <Box textAlign={"right"} marginTop={2}>
      <Link
        to={PathsConstant.marketPlace.list}
        style={{ textDecoration: "none" }}
      >
        <Typography
          variant="body1"
          style={{ color: StyleVariables["color-warning"] }}
        >
          {t("common.more")}
        </Typography>
      </Link>
    </Box>
    )
  }

  const mappingTodayPrices = (
    prices: any[],
    subProducts: SubProductModel[]
  ) => {
    const now = dayjs().format("YYYY-MM-DD");
    const todayPrices = prices?.filter((price: any) => price.date === now);
    const todayPriceMapping = subProducts.map((subProduct: any) => {
      const findPrice = todayPrices.find(
        (price: any) => price.subProductId === subProduct.id
      );
      return {
        ...findPrice || { averagePrice: '-' },
        ...subProduct,
      };
    });
    setPriceTodaySubProducts(todayPriceMapping.filter((price: any) => price.nameTh));
  };

  const mappingChartData = (prices: any[], subProducts: SubProductModel[]) => {
    const labels: string[] = Array.from(
      new Set(prices.map((price) => dayjs(price.date).format("DD/MM")))
    );
    const datasets: any[] = subProducts.map(
      (subProduct: SubProductModel, index: number) => {
        const arr: any[] = [];
        const startDate = dayjs().add(-15, "days").format("YYYY-MM-DD");
        const endDate = dayjs().format("YYYY-MM-DD");
        for (let date = startDate; date <= endDate; date = dayjs(date).add(1, "day").format("YYYY-MM-DD")) {
          const findPrice = prices.find((price: any) => price.date === date && price.subProductId === subProduct.id);
          arr.push(findPrice?.averagePrice || null);
        }
        return {
          label: i18n.language === "th" ? subProduct.nameTh : subProduct.nameEn,
          data: [...arr],
          borderColor: ColorPatternsConstant[index],
          backgroundColor: ColorPatternsConstant[index],
          borderWidth: 1,
        };
      }
    );
    setChartLabels([...labels]);
    setChartDatasets([...datasets]);
  };

  const handleNavigateToCreatePost = () => {
    UserStore.isLoggedIn
      ? navigate(PathsConstant.marketPlace.post)
      : UserStore.onOpenModalLogin();
  };

  const generatePostDescription = (post: PostModel) => {
    const description = post.description || "";
    const phoneNumber1 =
      (post.phoneNumbers && post.phoneNumbers[0]?.phoneNumber) || "";
    const phoneNumber2 =
      (post.phoneNumbers && post.phoneNumbers[0]?.phoneNumber) || "";
    const lineId =
      (post.lineId && `${t("marketplace.post.form.lineId")} ${post.lineId}`) ||
      "";
    return (
      description +
      (phoneNumber1 && ` ✆ ${phoneNumber1}`) +
      (phoneNumber2 && ` ✆ ${phoneNumber2}`) +
      (lineId && ` ${t("marketplace.post.form.lineId")} ${lineId}`)
    );
  };

  const getProductTypeName = (value: string) => {
    const findProductTypeByValue = TypesProductConstant.find(
      (product: any) => product.value === `${value}`
    );
    return (findProductTypeByValue && t(findProductTypeByValue?.label)) || "";
  };

  return (
    <GeneralLayout>
      <>
        <Box className="container-content">
          <Container>
            <Box paddingY={4} color={StyleVariables["color-black"]}>
              <GoogleAdsense
                slotDesktop={slotGoogleAdsenseDesktop || ""}
                slotMobile={slotGoogleAdsenseMobile || ""}
              />
              <Box display={"flex"} alignItems={"center"} marginBottom={4}>
                <Box
                  width={75}
                  height={75}
                  borderRadius={"100%"}
                  overflow={"hidden"}
                >
                  <img
                    src={
                      (product.storage?.id &&
                        `${apiUrl}/storages/${product.storage?.id}`) ||
                      LogoGray
                    }
                    alt={product.nameEn}
                    className="img-object-cover"
                  />
                </Box>
                <Box marginLeft={4}>
                  <Typography variant="h3" marginBottom={1}>
                    {t("product.todayPrice.title", {
                      productName:
                        i18n.language === "th"
                          ? product.nameTh
                          : product.nameEn,
                    })}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-neutral"]}
                  >
                    {t("product.todayPrice.subtitle", {
                      date: FullDateBuddhistEraUtil(dayjs()),
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={{ xs: "100%", md: "40% 60%" }}
                border={`1px solid ${StyleVariables["color-black"]}`}
                marginBottom={4}
              >
                <Box
                  paddingY={2}
                  paddingX={{ xs: 2, md: 3 }}
                  borderRight={{
                    xs: "unset",
                    md: `1px solid ${StyleVariables["color-black"]}`,
                  }}
                  borderBottom={{
                    xs: `1px solid ${StyleVariables["color-black"]}`,
                    md: "unset",
                  }}
                >
                  {priceTodaySubProducts.length ? (
                    <SubProductPriceList subProducts={priceTodaySubProducts} />
                  ) : (
                    <Typography
                      variant="body1"
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"100%"}
                      color={StyleVariables["color-neutral"]}
                    >
                      {t("product.todayPrice.noData", {
                        productName:
                          i18n.language === "th"
                            ? product.nameTh
                            : product.nameEn,
                      })}
                    </Typography>
                  )}
                </Box>
                <Box paddingY={4} paddingX={{ xs: 2, md: 3 }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginBottom={6}
                  >
                    <Typography variant="body1">
                      {t("product.todayPrice.titleLast15Days")}
                    </Typography>
                    <Link
                      to={PathsConstant.product.price.past(
                        productId || "",
                        `${dayjs()
                          .add(-15, "days")
                          .format("YYYYMMDD")}-${dayjs().format("YYYYMMDD")}`
                      )}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ color: StyleVariables["color-warning"] }}
                      >
                        {t("common.more")}
                      </Typography>
                    </Link>
                  </Box>
                  {chartDatasets?.length ? (
                    <LineGraphChart
                      labels={chartLabels}
                      datasets={chartDatasets}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      paddingBottom={4}
                      textAlign={"center"}
                      color={StyleVariables["color-neutral"]}
                    >
                      {t("product.todayPrice.noDataInLast15Days", {
                        productName:
                          i18n.language === "th"
                            ? product.nameTh
                            : product.nameEn,
                      })}
                    </Typography>
                  )}
                </Box>
              </Box>
              <SocialShare
                facebookQuery={`${frontUrl}${location.pathname}`}
                lineQuery={`${frontUrl}${location.pathname}`}
                xQuery={`${frontUrl}${location.pathname}`}
                contentType="product"
                contentId={`${product.id}` || ""}
              />
              <Box fontSize={14} fontWeight={300} marginTop={3}>
                {t("product.todayPrice.sourceTitle", {
                  productName:
                    i18n.language === "th" ? product.nameTh : product.nameEn,
                })}{" "}
                {product.resources?.map((resource, index) => {
                  return (
                    <LinkMaterial
                      key={index}
                      href={resource.serviceUrl}
                      target="_blank"
                      color={StyleVariables["color-primary"]}
                      underline="none"
                    >
                      {resource.name},{" "}
                    </LinkMaterial>
                  );
                })}
              </Box>
            </Box>
          </Container>
          <Box
            bgcolor={StyleVariables["color-secondary"]}
            paddingY={4}
            paddingX={{ xs: 1, md: 2 }}
            marginX={{ xs: -1, md: -2 }}
          >
            <Container>
              <Box
                marginBottom={3}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={{ xs: "flex-start", md: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                gap={2}
              >
                <Typography variant="h4" color={StyleVariables["color-black"]}>
                  {t("marketplace.title")}
                </Typography>
                <Box marginX={{ xs: "auto", sm: 0 }}>
                  <Button
                    variant="contained"
                    onClick={handleNavigateToCreatePost}
                  >
                    {t("marketplace.post")}
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                {/* Example Post */}
                {!posts.length ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <InviteUserPost />
                    </Grid>
                    <Grid item xs={12} md={4} position={"relative"}>
                      <MarketplacePostCard
                        id={""}
                        imageUrl={""}
                        type={getProductTypeName(TypesProductConstant[0].value)}
                        title={"มะนาว"}
                        volume={"100"}
                        unit={"กก."}
                        province={"กรุงเทพมหานคร"}
                        description={"ติดต่อ 099-999-99XX"}
                        announceImageUrl={""}
                        announceName={`${process.env.REACT_APP_Name}`}
                        announceDate={dayjs()}
                        isExample
                      />
                      <Typography
                        variant="h3"
                        color={StyleVariables["color-primary"]}
                        position={"absolute"}
                        left={"60%"}
                        top={"35%"}
                        width={250}
                        sx={{
                          transform: "translate(-60%, -35%)",
                          rotate: "-15deg",
                        }}
                      >
                        {t("marketplace.examplePostTitle")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {/* /.Example Post */}
                {posts.map((post, index) => {
                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <MarketplacePostCard
                        id={post.id || ""}
                        imageUrl={
                          (post.marketPostStorages &&
                            post.marketPostStorages[0]?.storage?.id &&
                            `${apiUrl}/storages/${post.marketPostStorages[0]?.storage?.id}`) ||
                          ""
                        }
                        type={getProductTypeName(post.type)}
                        title={post.title}
                        volume={`${post.volume}` || ""}
                        unit={post.unit || ""}
                        province={`${post.province}`}
                        description={generatePostDescription(post)}
                        announceImageUrl={
                          (post.user?.storage?.id &&
                            `${apiUrl}/storages/${post.user.storage.id}`) ||
                          ""
                        }
                        announceName={
                          (post.user?.userCredential &&
                            `${post.user.userCredential?.username}`) ||
                          ""
                        }
                        announceDate={post.createdAt || ""}
                        onClick={(id: string | number) =>
                          navigate(
                            PathsConstant.marketPlace.postDetail(`${id}`)
                          )
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              { showMore() }
            </Container>
          </Box>
        </Box>
      </>
    </GeneralLayout>
  );
});

export default PriceToday;

import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useMetaTags } from "react-metatags-hook";
import { observer } from "mobx-react";
import { Box, Container } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import { useStores } from "../../stores";
import GeneralLayout from "../../layouts/General";
import {
  Post as PostModel,
  PostComment as PostCommentModel,
} from "../../models/Marketplace";
import {
  GetPostById as GetPostByIdService,
  CommentPost as CommentPostService,
  UpdateCommentPost as UpdateCommentPostService,
  DeleteCommentDelete as DeleteCommentDeleteService,
} from "../../services/Marketplace";
import PostInformation from "../../components/PostInformation";
import Comment from "../../components/Comment";
import GoogleAdsense from "../../components/GoogleAdsense";

type Props = {};

const PostDetail = observer((props: Props) => {
  const slotGoogleAdsenseDesktop =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_POST_DETAIL_DESKTOP;
  const slotGoogleAdsenseMobile =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_POST_DETAIL_MOBILE;
  const apiUrl = process.env.REACT_APP_API_URL;
  const params = useParams();
  const { postId } = params;
  const { SnackbarStore, UserStore } = useStores();
  const { t } = useTranslation();
  const [post, setPost] = useState<PostModel>({
    title: "",
    description: "",
    type: TypesProductConstant[0].value,
    volume: 0,
    unit: "",
    price: 0,
  });
  const [comments, setComments] = useState<PostCommentModel[]>([]);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useMetaTags(
    {
      title: post.title,
      description: `${post.title} ${post.description}`,
      metas: [
        { property: "og:title", content: post.title },
        { property: "og:description", content: post.title },
        {
          property: "og:image",
          content:
            (post.marketPostStorages &&
              post.marketPostStorages[0] &&
              `${apiUrl}/storages/${post.marketPostStorages[0].storage?.id}`) ||
            "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content:
            post.user?.userCredential && post.user?.userCredential?.username,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: post.title },
        {
          name: "twitter:description",
          content: `${post.title} ${post.description}`,
        },
      ],
    },
    [post]
  );

  useEffect(() => {
    fetchPostById();
  }, []);

  const fetchPostById = async () => {
    const queryStringRead = query.get("read");
    SnackbarStore.onOpenSnackbarProcessing(t("marketplace.postIsFetching"));
    const response = await GetPostByIdService(
      postId || "",
      queryStringRead ? { read: true } : undefined
    );
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        (response.message && `${response.statusCode} ${response.message}`) ||
          t("marketplace.postFetchFail")
      );
      return;
    }
    setPost(response.data);
    setComments(response.data?.marketComments || []);
    response.data?.notifyCount && UserStore.updateNotifiesMe();
  };

  const handleComment = async (comment: string) => {
    SnackbarStore.onOpenSnackbarProcessing("");
    const response = await CommentPostService(postId || "", { comment });
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(t("marketplace.comment.fail"));
      return;
    }
    setComments([response.data, ...comments]);
  };

  const handleUpdateComment = async (id: string | number, comment: string) => {
    const response = await UpdateCommentPostService(id, { comment });
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.comment.fail")
      );
      return;
    }
    fetchPostById();
  };

  const handleDeleteComment = async (comment: PostCommentModel) => {
    const response = await DeleteCommentDeleteService(comment.id || "");
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.comment.delete.fail")
      );
      return;
    }
    fetchPostById();
  };

  return (
    <GeneralLayout>
      <Box className="bg-gradient-full">
        <Box className="container-content">
          <Container>
            <Box paddingY={4}>
              <GoogleAdsense
                slotDesktop={slotGoogleAdsenseDesktop || ""}
                slotMobile={slotGoogleAdsenseMobile || ""}
              />
              <PostInformation post={post} />
            </Box>
          </Container>
        </Box>
        <Box
          bgcolor={StyleVariables["color-white"]}
          paddingX={{ xs: 1, md: 2 }}
          paddingY={4}
        >
          <Container>
            <Comment
              comments={comments}
              onComment={(comment: string) => handleComment(comment)}
              onUpdateComment={(id: number | string, comment: string) =>
                handleUpdateComment(id, comment)
              }
              onDeleteComment={(comment: PostCommentModel) =>
                handleDeleteComment(comment)
              }
            />
          </Container>
        </Box>
      </Box>
    </GeneralLayout>
  );
});

export default PostDetail;

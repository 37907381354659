const th = {
  common: {
    or: "หรือ",
    submit: "ยืนยัน",
    ok: "ตกลง",
    edit: "แก้ไข",
    delete: "ลบ",
    cancel: "ยกเลิก",
    more: "เพิ่มเติม",
    saveEdit: "บันทึกการแก้ไข",
    selectImage: "เลือกรูป",
    pleaseEnter: "โปรดระบุ",
    formIsInvalidAlert: "กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน",
    fileUploadFailAlert: "อัปโหลดไฟล์ไม่สำเร็จ ขนาดไฟล์ต้องไม่เกิน 20MB",
    pleaseWaitAMoment: "กรุณารอสักครู่",
  },
  home: {
    heading: {
      compare: "เปรียบเทียบ",
      price: "ราคา",
      product: "สินค้าทางการเกษตร",
    },
    subtitle: "สามารถดูราคาย้อนหลัง แสดงผลเป็นกราฟหรือตารางได้",
  },
  authentication: {
    signIn: "เข้าสู่ระบบ",
    signInWithFacebook: "ล็อกอินด้วย Facebook",
    doNotHaveAnAccount: "หากยังไม่มีบัญชี",
    haveAnAccount: "หากมีบัญชีอยู่แล้ว",
    register: "สมัครสมาชิก",
    registerWithEmail: "สมัครสมาชิกด้วยอีเมล",
    forgotPassword: "ลืมรหัสผ่าน",
    email: "อีเมล",
    yourEmail: "อีเมลของคุณ",
    password: "รหัสผ่าน",
    backToSignIn: "กลับหน้าเข้าสู่ระบบ",
    signOut: "ออกจากระบบ",
    success: "เข้าสู่ระบบสำเร็จ",
    signInWithFacebookFail:
      "เข้าสู่ระบบด้วย facebook ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    isLoggingIn: "ระบบกำลังดำเนินการเข้าสู่ระบบ",
  },
  aboutUs: {
    title: "เกี่ยวกับเรา",
    heading: {
      comparePrice: "เปรียบเทียบราคา",
      product: "สินค้าทางการเกษตร",
    },
    subtitle:
      "kasetprice.com จัดทำขึ้นเพราะพบปัญหาในการหาราคาสินค้าเกษตร ทั้งเรื่องการกระจายอยู่ตามแหล่งต่างๆ ไม่สามารถดูใน SmartPhone ได้สะดวก KasetPrice.com จึงถูกสร้างขึ้นเพื่อรวมรวมราคาสินค้าเกษตรให้อยู่ในเว็บไซต์เดียว และสามารถดูใน SmartPhone ได้สะดวก รวมถึงแสดงข้อมูลในลักษณะกราฟ โดยในเริ่มแรกนั้น มีราคาสินค้าเกษตรเพียง 5 อย่างเท่านั้นคือ ยางพารา มันสําปะหลัง ปาล์มน้ำมัน ข้าวโพดเลี้ยงสัตว์ ข้าวเปลือก",
    mainFeatures: {
      title: "ฟีเจอร์หลัก",
      first: {
        title: "คำนวณราคาเฉลี่ย",
        description:
          "สินค้าทางการเกษตรให้อยู่ในเว็บไซต์เดียวและสามารถดูได้ใน Smartphone",
      },
      second: {
        title: "แสดงผลเป็นกราฟ",
        description:
          "สินค้าทางการเกษตรให้อยู่ในเว็บไซต์เดียวและสามารถดูได้ใน Smartphone",
      },
      third: {
        title: "ดูย้อนหลังได้",
        description:
          "สินค้าทางการเกษตรให้อยู่ในเว็บไซต์เดียวและสามารถดูได้ใน Smartphone",
      },
    },
  },
  contactUs: {
    title: "ติดต่อเรา",
    office: "สำนักงาน",
    officeName: "บริษัท โฟคอล โซลูชั่น จำกัด",
    location:
      "416/169 ซอยลาดพร้าว 87 (จันทราสุข) แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310",
  },
  profile: {
    title: "โปรไฟล์",
    displayName: "ชื่อที่ใช้แสดง",
    edit: "แก้ไขโปรไฟล์",
    email: "อีเมล",
    firstName: "ชื่อ",
    lastName: "นามสกุล",
    phoneNumber: "เบอร์โทรศัพท์",
    aboutMe: "เกี่ยวกับเรา",
    image: "รูปโปรไฟล์",
    isUpdating: "กำลังอัปเดตข้อมูลโปรไฟล์",
    updateSuccess: "อัปเดตโปรไฟล์เรียบร้อยแล้ว",
    updateFail: "อัปเดตโปรไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    memberOnly: "แสดงเฉพาะสมาชิกเท่านั้น",
  },
  marketplace: {
    title: "ตลาดนัดเกษตรไพรซ์",
    post: "ลงประกาศซื้อขาย",
    form: {
      title: "ประกาศซื้อขาย",
      iWouldLikeTo: "ฉันอยากจะ",
      productType: "ประเภทสินค้า",
      province: "จังหวัด",
      volume: "ปริมาณทั้งหมด",
      unit: "หน่วย",
      price: "ราคา (บาท)",
      topic: "หัวข้อ",
      topicPlaceholder: "ขายมะนาว 100 ลูก ( 5 บาท / ลูก ) กรุงเทพมหานคร",
      description: "ข้อมูลเพิ่มเติม",
      descriptionPlaceholder: "เช่น มีทุกสัปดาห์ ติดต่อ 089-995-XXXX",
      uploadImage: "แทรกรูป",
      lineId: "Line Id",
      phoneNumber: "เบอร์โทรศัพท์",
    },
    searchPlaceholder: "ค้นหาสินค้าในตลาดนัดฯ",
    postIsCreating: "กำลังสร้างประกาศการซื้อขาย",
    createPostFail: "สร้างประกาศการซื้อขายไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    postIsUpdating: "กำลังอัปเดตประกาศการซื้อขาย",
    updatePostFail: "อัปเดตประกาศการซื้อขายไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    postIsFetching: "กำลังดึงประกาศการซื้อขาย",
    postFetchFail: "เกิดข้อผิดพลาดในการดึงประกาศการซื้อขาย",
    table: {
      list: "รายการ",
      volume: "ปริมาณ",
      date: "ลงวันที่",
      announcer: "ผู้ประกาศ",
      managePost: "จัดการโพสต์",
      noData: "ไม่มีรายการประกาศการซื้อขาย",
    },
    volume: "ปริมาณ",
    source: "แหล่งผลิต",
    postDate: "วันที่โพส",
    viewProfile: "ดูโปรไฟล์",
    comment: {
      title: "ความคิดเห็น",
      placeholderInput: "แสดงความคิดเห็นของคุณ",
      fail: "เกิดข้อผิดพลาดในการแสดงความคิดเห็นบนประกาศ",
      updating: "กำลังอัปเดตความคิดเห็นของคุณ",
      noData: "ไม่มีความคิดเห็น",
      delete: {
        title: "ลบความคิดเห็น",
        description: "คุณต้องการลบความคิดเห็นใช่หรือไม่ ?",
        fail: "เกิดข้อผิดพลาดในการลบความคิดเห็นบนประกาศ",
      },
    },
    deletePost: {
      title: "ลบประกาศการซื้อขาย",
      description: "คุณต้องการลบประกาศการซื้อขายใช่หรือไม่ ?",
      fail: "เกิดข้อผิดพลาดในการลบประกาศการซื้อขาย",
      processing: "กำลังลบประกาศการซื้อขาย",
    },
    inviteUser: "ร่วมเป็นส่วนหนึ่งของตลาดนัดเกษตรไพรซ์",
    examplePostTitle: "ตัวอย่างประกาศซื้อขาย",
  },
  product: {
    todayPrice: {
      title: "ราคา{{productName}}",
      subtitle: "ประจำวันที่ {{date}}",
      noData: "ไม่มีข้อมูลราคา{{productName}}วันนี้",
      titleLast15Days: "กราฟราคา 15 วันล่าสุด (บาท)",
      noDataInLast15Days: "ไม่มีข้อมูลราคา{{productName}}ใน 15 วันที่ผ่านมา",
      sourceTitle: "ข้อมูลนี้เป็นราคา{{productName}}ที่ได้จากเว็บไซต์",
      baht: "บาท",
    },
    pastPrice: {
      title1: "ราคา{{productName}}",
      title2: "ราคา{{productName}}ย้อนหลัง",
      startDate: "ตั้งแต่วันที่",
      endDate: "ถึงวันที่",
      selectSource: "เลือกแหล่งข้อมูล",
      displayAs: "แสดงผลเป็น",
      graph: "กราฟ",
      table: "ตาราง",
      noData: "ไม่มีข้อมูลราคา{{productName}}",
      date: "วันที่",
      dataFetchFail: "เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง",
    },
    type: {
      all: "ทั้งหมด",
      sell: "ขาย",
      buy: "รับซื้อ",
    },
  },
};

export default th;

import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { ColorPatterns as ColorPatternsConstant } from "../../constants/Product";
import { FullDateBuddhistEra as FullDateBuddhistEraUtil } from "../../utils/DateFormat";

type Props = {
  heads: any[];
  productPrices: any[];
  showDate: boolean
};

const ProductPriceCompare = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box overflow={"auto"} maxHeight={{ xs: 350, md: 550 }}>
        <Box
          display={"grid"}
          gridTemplateColumns={`minmax(120px, 2fr) repeat(${props.heads.length}, minmax(60px, 1fr))`}
          position={"sticky"}
          top={0}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            paddingX={0.5}
            height={40}
            bgcolor={"#b3d7ab"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {t("product.pastPrice.date")}
          </Typography>
          {props.heads?.map((head, index) => {
            return (
              <Box
                key={`head-${index}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                paddingX={0.5}
                bgcolor={"#b3d7ab"}
                height={40}
              >
                <Box
                  width={10}
                  height={10}
                  borderRadius={"100%"}
                  bgcolor={ColorPatternsConstant[index]}
                  marginRight={1}
                />
                <Typography variant="body1" fontWeight={500}>{`(${
                  index + 1
                })`}</Typography>
              </Box>
            );
          })}
        </Box>
        {props.productPrices?.map((productPrice: any, index: number) => {
          return (
            <Box
              key={`body-${index}`}
              display={"grid"}
              gridTemplateColumns={`minmax(120px, 2fr) repeat(${props.heads.length}, minmax(60px, 1fr))`}
            >
              <Typography
                variant="body1"
                paddingX={0.5}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                bgcolor={
                  index % 2 === 0
                    ? StyleVariables["color-secondaryLight"]
                    : StyleVariables["color-secondary"]
                }
                height={40}
              >
                {productPrice[0]?.date &&
                  FullDateBuddhistEraUtil(productPrice[0]?.date, props.showDate)}
              </Typography>
              {productPrice.map((price: any, ind: number) => {
                return (
                  <Typography
                    variant="body1"
                    key={`body-column-${ind}-${props.heads[ind]}`}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    paddingX={0.5}
                    bgcolor={
                      index % 2 === 0
                        ? StyleVariables["color-secondaryLight"]
                        : StyleVariables["color-secondary"]
                    }
                    height={40}
                  >
                    {Number(price.averagePrice) % 1 !== 0
                      ? Number(price.averagePrice).toFixed(2)
                      : price.averagePrice}
                  </Typography>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <Box
        marginTop={2}
        display={"flex"}
        flexWrap={"wrap"}
        columnGap={3}
        rowGap={1}
      >
        {props.heads?.map((head, index) => {
          return (
            <Box display={"flex"} alignItems={"center"} key={index}>
              <Box
                width={15}
                height={15}
                borderRadius={"100%"}
                bgcolor={ColorPatternsConstant[index]}
                marginRight={1}
              />
              <Typography variant="body1">{head.label}</Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default ProductPriceCompare;

const en = {
  common: {
    or: "Or",
    submit: "Submit",
    ok: "Submit",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    more: "More",
    saveEdit: "Save",
    selectImage: "Select Image",
    pleaseEnter: "Please Enter",
    formIsInvalidAlert:
      "Please fill in the information correctly and completely.",
    fileUploadFailAlert:
      "Failed to upload file (file size must not exceed 20 MB)",
    pleaseWaitAMoment: "Please wait a moment.",
  },
  home: {
    heading: {
      compare: "Compare ",
      price: "Price",
      product: "Agricultural Products",
    },
    subtitle:
      "Can see past prices results can be displayed as graphs or tables.",
  },
  authentication: {
    signIn: "Sign In",
    signInWithFacebook: "Sign In With Facebook",
    doNotHaveAnAccount: "Don't have an account ?",
    haveAnAccount: "Have an account",
    register: "Register",
    registerWithEmail: "Register With Email",
    forgotPassword: "Forgot Password",
    email: "Email",
    yourEmail: "Your Email",
    password: "Password",
    backToSignIn: "Back to Sign In",
    signOut: "Sign Out",
    success: "Sign in success",
    signInWithFacebookFail: "Can't sign in with facebook, please try again",
    isLoggingIn: "The system is logging in.",
  },
  profile: {
    title: "Profile",
    displayName: "Display Name",
    edit: "Edit Profile",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    aboutMe: "About Me",
    image: "Profile Image",
    isUpdating: "Profile is updating",
    updateSuccess: "Profile update success",
    updateFail: "Can't update profile, please try again",
    memberOnly: "Member Only",
  },
  aboutUs: {
    title: "About Us",
    heading: {
      comparePrice: "Compare Price",
      product: "Agricultural Products",
    },
    subtitle:
      "kasetprice.com created because there was a problem in finding prices for agricultural products. Both the matter of distribution in various sources and unable to view easily on smartphone. Therefore, it was created to aggregate agricultural product prices into one website and can be easily viewed on a smartphone. Including displaying data in graph. In the beginning There are prices for only 5 agricultural products: rubber, cassava, oil palm, animal feed corn, and paddy rice.",
    mainFeatures: {
      title: "Main Features",
      first: {
        title: "Price Average",
        description:
          "Agricultural products are available on one website and can be viewed on a smartphone.",
      },
      second: {
        title: "Display On Graph",
        description:
          "Agricultural products are available on one website and can be viewed on a smartphone.",
      },
      third: {
        title: "Price History",
        description:
          "Agricultural products are available on one website and can be viewed on a smartphone.",
      },
    },
  },
  contactUs: {
    title: "Contact Us",
    office: "Office",
    officeName: "Focal Solution Co., Ltd.",
    location:
      "416/169 Soi Lad Phrao 87 (Chanthra Suk), Khlong Chaokhun Sing, Wang Thonglang, Bangkok 10310.",
  },
  marketplace: {
    title: "Marketplace",
    post: "Post to marketplace",
    form: {
      title: "Post To Marketplace",
      iWouldLikeTo: "I Would Like To",
      productType: "Product Type",
      province: "Province",
      volume: "Volume",
      unit: "Unit",
      price: "Price (baht)",
      topic: "Topic",
      topicPlaceholder: "Sell 100 lemons ( 5 baht / unit ) Bangkok",
      description: "Description",
      descriptionPlaceholder:
        "For example, we have it every week. Contact 089-995-XXXX.",
      uploadImage: "Upload Image",
      lineId: "Line Id",
      phoneNumber: "Phone Number",
    },
    searchPlaceholder: "Search the posts in marketplace",
    postIsCreating: "Post is creating",
    createPostFail: "Can't create a post, please try again",
    postIsUpdating: "Post is updating",
    updatePostFail: "Can't update a post, please try again",
    postIsFetching: "Retrieving the posts",
    postFetchFail: "An error occurred retrieving the posts.",
    table: {
      list: "List",
      volume: "Volume",
      date: "Date Posted",
      announcer: "Announcer",
      managePost: "Manage Post",
      noData: "Not found the post in marketplace",
    },
    volume: "Volume",
    source: "Source",
    postDate: "Date Post",
    viewProfile: "View Profile",
    comment: {
      title: "Comment",
      placeholderInput: "Enter your comment",
      fail: "An error occurred commenting the post",
      updating: "Your comment is updating",
      noData: "No comment",
      delete: {
        title: "Delete a comment",
        description: "Are you sure delete a comment ?",
        fail: "An error occurred delete a comment",
      },
    },
    deletePost: {
      title: "Delete a post",
      description: "Are you sure delete a post ?",
      fail: "An error occurred delete a post",
      processing: "Post is deleting",
    },
    inviteUser: "Be a part of the Kasetprice",
    examplePostTitle: "Example Post",
  },
  product: {
    todayPrice: {
      title: "Today {{productName}} Price",
      subtitle: "{{date}}",
      noData: "No data on the price of {{productName}} today.",
      titleLast15Days: "Price Chart For The last 15 Days (Baht)",
      noDataInLast15Days:
        "There is no {{productName}} price for the past 15 days.",
      sourceTitle:
        "This information is the price of {{productName}} obtained from the website",
      baht: "Baht",
    },
    pastPrice: {
      title1: "{{productName}} Price",
      title2: "Past price of {{productName}}",
      startDate: "Start Date",
      endDate: "End Date",
      selectSource: "Select Source",
      displayAs: "Display As",
      graph: "Graph",
      table: "Table",
      noData: "No data on the price of {{productName}}",
      date: "Date",
      dataFetchFail: "An error occurred retrieving data, please try again.",
    },
    type: {
      all: "All",
      sell: "Sell",
      buy: "Buy",
    },
  },
};

export default en;

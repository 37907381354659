import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Container,
  Typography,
  Button,
  Hidden,
  Paper,
  MenuList,
  MenuItem,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../assets/styles/Header.scss";
import LogoFull from "../assets/images/LogoFull.png";
import { Paths as PathsConstant } from "../constants/Route";
import {
  MobileGuestMenu as MobileGuestMenuConstant,
  MobileUserMenu as MobileUserMenuConstant,
} from "../constants/UserMenu";
import { useStores } from "../stores";
import ImageIcon from "../components/ImageIcon";
import SearchProductInput from "./inputs/SearchProduct";
import AuthenticationModal from "./modals/Authentication";
import NotifyDot from "./NotifyDot";
import { useDebouncedCallback } from "use-debounce";
import OneSignal from "react-onesignal";

type Props = {};

const Header = observer((props: Props) => {
  const appName = process.env.REACT_APP_Name;
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { UserStore } = useStores();
  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] =
    useState<boolean>(false);
  const [isShowSearchOnMobile, setIsShowSearchOnMobile] =
    useState<boolean>(false);

  useEffect(() => {
    isShowSearchOnMobile && setHamburgerMenuIsOpen(false);
  }, [isShowSearchOnMobile]);

  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      onDebounceUpdateNotifiesMe
    );
  }, []);

  const onDebounceUpdateNotifiesMe = useDebouncedCallback(() => {
    UserStore.updateNotifiesMe();
  }, 1000);

  return (
    <>
      <header className="header">
        <Box className="container-content">
          <Container>
            {/* Desktop */}
            <Hidden mdDown>
              <Box
                display={"grid"}
                gridTemplateColumns={"2fr 6fr 4fr"}
                gap={4}
                alignItems={"center"}
                height={"100%"}
              >
                <Link to={PathsConstant.home} style={{ display: "flex" }}>
                  <img
                    src={LogoFull}
                    alt={appName}
                    style={{ height: 30, objectFit: "contain" }}
                  />
                </Link>
                <Box>
                  {location.pathname !== PathsConstant.home && (
                    <SearchProductInput />
                  )}
                </Box>
                <Box width={"100%"} whiteSpace={"nowrap"}>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <Link
                      to={PathsConstant.marketPlace.list}
                      className="market-trade-link"
                    >
                      <Typography variant="body1">
                        {t("marketplace.title")}
                      </Typography>
                    </Link>
                    {!UserStore.isLoggedIn ? (
                      <Button
                        variant="outlined"
                        onClick={UserStore.onOpenModalLogin}
                      >
                        {t("authentication.signIn")}
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigate(PathsConstant.marketPlace.post)
                          }
                        >
                          {t("marketplace.post")}
                        </Button>
                        {UserStore.user.user?.storage?.id ? (
                          <Box position={"relative"}>
                            <Box
                              width={40}
                              height={40}
                              borderRadius={"100%"}
                              overflow={"hidden"}
                              marginLeft={3}
                              className="cursor-pointer"
                              onClick={() => navigate(PathsConstant.profile.me)}
                            >
                              <img
                                src={`${apiUrl}/storages/${UserStore.user.user?.storage?.id}`}
                                alt={`img-profile-${UserStore.user.username}`}
                                className="img-object-cover"
                              />
                            </Box>
                            {UserStore.user.notifyCount ? (
                              <Box position={"absolute"} top={-5} right={-5}>
                                <NotifyDot
                                  numberOfNotify={UserStore.user.notifyCount}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        ) : (
                          <Box position="relative">
                            <Box
                              className="profile-image"
                              marginLeft={3}
                              position={"relative"}
                              onClick={() => navigate(PathsConstant.profile.me)}
                            >
                              <ImageIcon iconClassName="image-icon-gray avatar" />
                            </Box>
                            {UserStore.user.notifyCount ? (
                              <Box position={"absolute"} top={-5} right={-5}>
                                <NotifyDot
                                  numberOfNotify={UserStore.user.notifyCount}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Hidden>
            {/* /.Desktop */}

            {/* Mobile */}
            <Hidden mdUp>
              {!isShowSearchOnMobile ? (
                <Box
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  position={"relative"}
                >
                  <Box position={"relative"}>
                    <Box className="menu-toggle">
                      <input
                        checked={hamburgerMenuIsOpen}
                        type="checkbox"
                        onChange={() =>
                          setHamburgerMenuIsOpen(!hamburgerMenuIsOpen)
                        }
                      />
                      <span />
                      <span />
                      <span />
                    </Box>
                    {UserStore.user.notifyCount ? (
                      <Box position={"absolute"} top={-5} right={-5} zIndex={1}>
                        <NotifyDot
                          numberOfNotify={UserStore.user.notifyCount}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Link
                    to={PathsConstant.home}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={LogoFull}
                      alt={appName}
                      style={{ height: 30, objectFit: "contain" }}
                    />
                  </Link>
                  <Box
                    className="cursor-pointer"
                    onClick={() => setIsShowSearchOnMobile(true)}
                  >
                    <ImageIcon iconClassName="image-icon-green search" />
                  </Box>
                  <Paper
                    className={`user-menu ${
                      !hamburgerMenuIsOpen && "user-menu-hided"
                    }`}
                  >
                    <MenuList>
                      {!UserStore.isLoggedIn
                        ? MobileGuestMenuConstant.map((menu, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => navigate(menu.path)}
                              >
                                <Typography variant="body2">
                                  {t(menu.label)}
                                </Typography>
                                <Box
                                  marginLeft={"auto"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"flex-end"}
                                >
                                  <ImageIcon
                                    iconClassName={`image-icon-green ${menu.icon}`}
                                  />
                                </Box>
                              </MenuItem>
                            );
                          })
                        : MobileUserMenuConstant.map((menu, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => navigate(menu.path)}
                              >
                                <Box display={"flex"} gap={2}>
                                  <Typography variant="body2">
                                    {t(menu.label)}
                                  </Typography>
                                  {menu.label === "profile.title" &&
                                  UserStore.user.notifyCount ? (
                                    <NotifyDot
                                      numberOfNotify={
                                        UserStore.user.notifyCount
                                      }
                                    />
                                  ) : null}
                                </Box>
                                <Box
                                  marginLeft={"auto"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"flex-end"}
                                >
                                  <ImageIcon
                                    iconClassName={`image-icon-green ${menu.icon}`}
                                  />
                                </Box>
                              </MenuItem>
                            );
                          })}
                      {!UserStore.isLoggedIn && (
                        <MenuItem onClick={() => UserStore.onOpenModalLogin()}>
                          <Typography variant="body2">
                            {t("authentication.signIn")}
                          </Typography>
                          <Box
                            marginLeft={"auto"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                          >
                            <ImageIcon
                              iconClassName={`image-icon-green avatar`}
                            />
                          </Box>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Paper>
                </Box>
              ) : (
                <Box
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  paddingX={2}
                >
                  <SearchProductInput
                    isShowBackButton={true}
                    onClickBackButton={() => setIsShowSearchOnMobile(false)}
                  />
                </Box>
              )}
            </Hidden>
            {/* /.Mobile */}
          </Container>
        </Box>
      </header>
      <AuthenticationModal
        isOpen={UserStore.isOpenModalLogin}
        onClose={UserStore.onCloseModalLogin}
      />
    </>
  );
});

export default Header;

import { Axios } from "../plugins";
import {
  CreatePost as CreatePostModel,
  UpdatePost as UpdatePostModel,
} from "../models/Marketplace";

const createPost = async (body: CreatePostModel) => {
  try {
    const { data } = await Axios.post("/market-places/posts", body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const uploadImagePost = async (postId: string | number, file: File) => {
  try {
    const form = new FormData();
    form.append("file", file);
    const { data } = await Axios.post(
      `/market-places/posts/${postId}/upload`,
      form
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getPosts = async (query: any) => {
  try {
    const { data } = await Axios.get("/market-places/posts", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getPostByProductId = async (id: string | number) => {
  try {
    const { data } = await Axios.get(`/market-places/products/${id}/posts`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getPostById = async (
  id: string | number,
  params?: {
    read: boolean;
  }
) => {
  try {
    const { data } = await Axios.get(`/market-places/posts/${id}`, { params });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const commentPost = async (id: string | number, body: any) => {
  try {
    const { data } = await Axios.post(
      `/market-places/posts/${id}/comments`,
      body
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const updateCommentPost = async (id: string | number, body: any) => {
  try {
    const { data } = await Axios.patch(`/market-places/comments/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const deleteCommentDelete = async (id: string | number) => {
  try {
    const { data } = await Axios.delete(`/market-places/comments/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getMyPosts = async (query: any) => {
  try {
    const { data } = await Axios.get("market-places/my-posts", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data.message,
    };
  }
};

const deletePost = async (id: string | number) => {
  try {
    const { data } = await Axios.delete(`/market-places/posts/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getPostsByUserId = async (id: string | number, query: any) => {
  try {
    const { data } = await Axios.get(`/market-places/users/${id}/posts`, {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const updatePostById = async (id: string | number, body: UpdatePostModel) => {
  try {
    const { data } = await Axios.patch(`/market-places/posts/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const deletePostImageById = async (
  postId: string | number,
  id: string | number
) => {
  try {
    const { data } = await Axios.delete(
      `/market-places/posts/${postId}/images/${id}`
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

export {
  createPost as CreatePost,
  uploadImagePost as UploadImagePost,
  getPosts as GetPosts,
  getPostByProductId as GetPostByProductId,
  getPostById as GetPostById,
  commentPost as CommentPost,
  updateCommentPost as UpdateCommentPost,
  deleteCommentDelete as DeleteCommentDelete,
  getMyPosts as GetMyPosts,
  deletePost as DeletePost,
  getPostsByUserId as GetPostsByUserId,
  updatePostById as UpdatePostById,
  deletePostImageById as DeletePostImageById,
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Link as LinkMui, Button } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../constants/Product";
import { Paths as PathsConstant } from "../constants/Route";
import { Post as PostModel } from "../models/Marketplace";
import { DateBuddhistEra as DateBuddhistEraUtil } from "../utils/DateFormat";
import { AesEncryption as AesEncryptionUtil } from "../utils/Crypto";
import SocialShare from "./SocialShare";
import ImageIcon from "./ImageIcon";
import BackdropImageSlides from "./slides/BackdropImage";

type Props = {
  post: PostModel;
};

const PostInformation = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const frontUrl = process.env.REACT_APP_FRONT_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOpenBackdropImageSlides, setIsOpenBackdropImageSlides] =
    useState<boolean>(false);
  const [defaultIndexImagePreview, setDefaultIndexImagePreview] =
    useState<number>(0);

  const getProductTypeName = (value: string) => {
    const findProductTypeByValue = TypesProductConstant.find(
      (product) => product.value === `${value}`
    );
    return (findProductTypeByValue && t(findProductTypeByValue?.label)) || "";
  };

  const onOpenBackdropImageSlides = (index: number) => {
    setDefaultIndexImagePreview(index);
    setIsOpenBackdropImageSlides(true);
  };

  const onCloseBackdropImageSlides = () => {
    setDefaultIndexImagePreview(0);
    setIsOpenBackdropImageSlides(false);
  };

  const handleNavigateToAnnouncerProfile = () => {
    const idEncrypted = `${AesEncryptionUtil(`${props.post.user?.id}`)}`;
    const encoding: string = encodeURIComponent(idEncrypted);
    navigate(PathsConstant.marketPlace.announcerProfile(encoding));
  };

  return (
    <>
      <Box bgcolor={StyleVariables["color-white"]} padding={{ xs: 1, md: 4 }}>
        <Box
          display={"grid"}
          gridTemplateColumns={{
            xs: "1fr",
            md: "1fr 1fr",
          }}
          columnGap={{ xs: 1, md: 2 }}
          rowGap={1}
          textAlign={{ xs: "center", md: "left" }}
          paddingBottom={4}
        >
          <Box
            display={"flex"}
            justifyContent={{ xs: "center", md: "flex-start" }}
            gap={3}
          >
            <Typography
              variant="h5"
              color={StyleVariables["color-warning"]}
              className="text-underline"
            >
              {getProductTypeName(props.post.type)}
            </Typography>
            <Link
              to={PathsConstant.product.price.today(
                `${props.post.product?.id}`
              )}
            >
              <Typography
                variant="h5"
                color={StyleVariables["color-primary"]}
                className="text-underline"
              >
                {i18n.language === "th"
                  ? props.post.product?.nameTh
                  : props.post.product?.nameEn}
              </Typography>
            </Link>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 1fr 1fr"}
            justifyContent={"center"}
            gap={{ xs: 1, md: 2 }}
          >
            <Box>
              <Typography variant="label">{t("marketplace.volume")}</Typography>
              <Typography variant="h6" color={StyleVariables["color-primary"]}>
                {props.post.volume} {props.post.unit}
              </Typography>
            </Box>
            <Box>
              <Typography variant="label">{t("marketplace.source")}</Typography>
              <Typography variant="h6" color={StyleVariables["color-primary"]}>
                {props.post.province}
              </Typography>
            </Box>
            <Box>
              <Typography variant="label">
                {t("marketplace.postDate")}
              </Typography>
              <Typography variant="h6" color={StyleVariables["color-primary"]}>
                {(props.post.createdAt &&
                  DateBuddhistEraUtil(props.post.createdAt)) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          paddingTop={4}
          paddingBottom={1}
          borderTop={`1px solid ${StyleVariables["color-neutralLight"]}`}
          borderBottom={`1px solid ${StyleVariables["color-neutralLight"]}`}
        >
          <Box>
            <Typography variant="h4" color={StyleVariables["color-black"]}>
              {props.post.title} {props.post.description}
              {props.post.phoneNumbers?.map((phoneNumber, index) => {
                return (
                  <LinkMui
                    key={index}
                    href={`tel:${phoneNumber.phoneNumber}`}
                    marginLeft={2}
                  >
                    ✆ {phoneNumber.phoneNumber}
                  </LinkMui>
                );
              })}{" "}
              {props.post.lineId && (
                <>
                  &nbsp;&nbsp;{t("marketplace.form.lineId")}&nbsp;:&nbsp;
                  <LinkMui
                    href={`http://line.me/ti/p/${props.post.lineId}`}
                    target="_blank"
                  >
                    {props.post.lineId}
                  </LinkMui>
                </>
              )}
            </Typography>
          </Box>
          <Box display={"flex"} gap={3} flexWrap={"wrap"} marginTop={2}>
            {props.post.marketPostStorages?.map((marketPostStorage, index) => {
              return (
                <Box
                  key={index}
                  minWidth={100}
                  minHeight={100}
                  width={100}
                  height={100}
                  borderRadius={0.5}
                  overflow={"hidden"}
                  className="cursor-pointer"
                  onClick={() => onOpenBackdropImageSlides(index)}
                >
                  <img
                    src={`${apiUrl}/storages/${marketPostStorage.storage?.id}`}
                    alt={`preview-${index}`}
                    className="img-object-cover"
                  />
                </Box>
              );
            })}
          </Box>
          <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
            <SocialShare
              iconSize={30}
              facebookQuery={`${frontUrl}${location.pathname}`}
              lineQuery={`${frontUrl}${location.pathname}`}
              xQuery={`${frontUrl}${location.pathname}`}
              contentType="post"
              contentId={`${props.post.id}` || ""}
            />
          </Box>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={{ xs: "1fr", md: "56px 5fr 1fr" }}
          gap={{ xs: 1, md: 4 }}
          alignItems={"center"}
          marginTop={2}
        >
          {props.post.user?.storage?.id ? (
            <Box
              width={56}
              height={56}
              minWidth={56}
              minHeight={56}
              borderRadius={"100%"}
              overflow={"hidden"}
            >
              <img
                src={`${apiUrl}/storages/${props.post.user?.storage?.id}`}
                alt={`profile-${props.post.user?.firstName}`}
                className="img-object-cover"
              />
            </Box>
          ) : (
            <Box className="profile-img-avatar-default">
              <ImageIcon iconClassName="image-icon-gray avatar" />
            </Box>
          )}
          <Box>
            <Typography
              variant="subtitle1"
              color={StyleVariables["color-primary"]}
            >
              {props.post.user?.userCredential &&
                props.post.user?.userCredential?.username}
            </Typography>
            <Typography
              variant="body1"
              color={StyleVariables["color-neutral"]}
              className="text-2-ellipse"
            >
              {props.post.user?.description || "-"}
            </Typography>
          </Box>
          <Box marginLeft={"auto"}>
            <Button
              variant="outlined"
              onClick={handleNavigateToAnnouncerProfile}
            >
              {t("marketplace.viewProfile")}
            </Button>
          </Box>
        </Box>
      </Box>
      {isOpenBackdropImageSlides && (
        <BackdropImageSlides
          images={props.post.marketPostStorages || []}
          isUseStorage={true}
          defaultIndex={defaultIndexImagePreview}
          onClose={onCloseBackdropImageSlides}
        />
      )}
    </>
  );
};

export default PostInformation;
